/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.spots').controller('overrideIngestDialogController', ['$state', '$mdDialog', 'EnumService', 'spot', function ($state, $mdDialog, EnumService, spot) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.trimmingOption = spot.trimmingOption;
    vm.leadingBlackFrameCount = spot.leadingBlackFrameCount;
    vm.slateFrameCount = spot.slateFrameCount;
    vm.postSlateBlackFrameCount = spot.postSlateBlackFrameCount;
    vm.trailingBlackFrameCount = spot.trailingBlackFrameCount;
    /* BINDABLE : METHODS*/

    vm.cancel = cancel;
    vm.save = save;
    vm.setTrimming = setTrimming;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function cancel() {
      $mdDialog.cancel();
    }

    function save() {
      $mdDialog.hide({
        trimmingOption: vm.trimmingOption,
        leadingBlackFrameCount: vm.leadingBlackFrameCount,
        slateFrameCount: vm.slateFrameCount,
        postSlateBlackFrameCount: vm.postSlateBlackFrameCount,
        trailingBlackFrameCount: vm.trailingBlackFrameCount
      });
    }

    function setTrimming() {
      switch (vm.trimmingOption) {
        case 'RTA':
          vm.leadingBlackFrameCount = 0;
          vm.slateFrameCount = 0;
          vm.postSlateBlackFrameCount = 0;
          vm.trailingBlackFrameCount = 0;
          break;

        case 'SPEC_B':
          vm.leadingBlackFrameCount = 0;
          vm.slateFrameCount = 150;
          vm.postSlateBlackFrameCount = 60;
          vm.trailingBlackFrameCount = 0;
          break;
        // Handle OTHER and any unknown cases

        default:
          vm.leadingBlackFrameCount = spot.leadingBlackFrameCount;
          vm.slateFrameCount = spot.slateFrameCount;
          vm.postSlateBlackFrameCount = spot.postSlateBlackFrameCount;
          vm.trailingBlackFrameCount = spot.trailingBlackFrameCount;
          break;
      }
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {
      EnumService.getEnum('Spot', 'trimmingOption').then(function (trimmingOptions) {
        vm.trimmingOptions = trimmingOptions;
      }, function () {
        vm.trimmingOptions = [];
      });
    }
  }]);
})();